import React from "react"
import { FaArrowDown } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"
import { styles } from "../../globals"
import themeObjectFromYaml from "../../theme/theme.yaml"

const theme = themeObjectFromYaml

class Hero extends React.Component {
  scrollToContent = e => {
    document.getElementById("separator").scrollIntoView({
      block: "start",
      behavior: "smooth",
    })
  }
  render() {
    const { backgrounds } = this.props

    return (
      <HeroWrapper backgrounds={backgrounds}>
        <section className="hero">
          <p className="heroText">
            <FormattedMessage id="discover" />
          </p>
          <button onClick={this.scrollToContent} aria-label="scroll">
            <FaArrowDown />
          </button>
        </section>
        <span id="separator" />
      </HeroWrapper>
    )
  }
}

export default Hero

const HeroWrapper = styled.div`
  .hero {
    align-items: center;
    background: ${theme.hero.background};
    background-image: url(${props => props.backgrounds.mobile});
    background-size: cover;
    color: ${theme.text.color.primary.inverse};
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    min-height: 90vh;
    height: 100px;
    padding: ${theme.space.inset.l};
    padding-top: ${theme.header.height.homepage};
  }

  .heroText {
    color: ${styles.mainWhite};
    font-size: 2.1rem;
    font-weight: 900;
    text-transform: capitalize;
  }

  #separator {
    display: block;
    margin-bottom: 2.6rem;
  }
  h1 {
    text-align: center;
    font-size: ${theme.hero.h1.size};
    margin: ${theme.space.stack.l};
    color: ${theme.hero.h1.color};
    line-height: ${theme.hero.h1.lineHeight};

    :global(strong) {
      position: relative;

      &::after,
      &::before {
        content: "›";
        color: ${theme.text.color.attention};
        margin: 0 ${theme.space.xs} 0 0;
        text-shadow: 0 0 ${theme.space.s} ${theme.color.neutral.gray.k};
      }
      &::after {
        content: "‹";
        margin: 0 0 0 ${theme.space.xs};
      }
    }
  }

  button {
    background: transparent;
    border: solid 3px white;
    border-radius: 50%;
    font-size: ${theme.font.size.m};
    padding: ${theme.space.s} ${theme.space.m};
    cursor: pointer;
    width: ${theme.space.xl};
    height: ${theme.space.xl};

    &:focus {
      outline-style: none;
      background: ${theme.color.brand.primary.active};
    }
    & > svg {
      position: relative;
      top: 5px;
      fill: hotpink;
      stroke-width: 40;
      stroke: hotpink;
      animation-duration: 2s;
      animation-name: buttonIconMove;
      animation-iteration-count: infinite;
    }
  }

  @keyframes buttonIconMove {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-6px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @media (min-width: ${styles.xSmallScreenBreakpoint}) {
    .hero {
      background-image: url(${props => props.backgrounds.tablet});
    }

    h1 {
      max-width: 90%;
      font-size: ${`calc(${theme.hero.h1.size} * 1.3)`};
    }

    button {
      font-size: ${theme.font.size.l};
    }
  }

  @media (min-width: ${styles.smallScreenBreakpoint}) {
    .hero {
      background-image: url(${props => props.backgrounds.desktop});
      min-height: 100vh;
    }

    h1 {
      max-width: 80%;
      font-size: ${`calc(${theme.hero.h1.size} * 1.5)`};
    }

    button {
      font-size: ${theme.font.size.xl};
    }
  }
`
