import React from "react"
import Article from "../components/article/Article"
import Section from "../components/Section"
import { FormattedMessage } from "react-intl"
import Gallery from "../components/Gallery"
import Hero from "../components/hero"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"

const IndexPage = ({ data, pageContext: { locale }, pageContext }) => {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  const {
    bgDesktop: {
      resize: { src: desktop },
    },
    bgTablet: {
      resize: { src: tablet },
    },
    bgMobile: {
      resize: { src: mobile },
    },
  } = data
  const backgrounds = {
    desktop,
    tablet,
    mobile,
  }

  return (
    <Layout locale={locale} pageContext={pageContext}>
      <SEO title="Hostel Ljubas" pathbame="/contact/" />
      <Hero backgrounds={backgrounds} />
      <Section>
        <Article id="separator" title={frontmatter.title}>
          {html}
        </Article>
      </Section>
      <Section>
        <Gallery />
      </Section>
    </Layout>
  )
}

export const indexQuery = graphql`
  query($path: String) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
    bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-image/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-image/" } }) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-image/" } }) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
  }
`
export default IndexPage
